export function handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        throw new HttpError(response.statusText, response.status);
    }

    if (response.status === 204) {
        return Promise.resolve({} as T);
    }

    return response.json() as Promise<T>;
}

export class HttpError extends Error {
    statusCode: number;
    constructor(message: string, statusCode: number) {
        super(message);
        this.statusCode = statusCode;
    }
}
