import React, { useEffect, useState } from 'react';
import { Button, Flex, Stack, useToast } from '@chakra-ui/react';
import SBInput from 'components/atoms/textfield/SBInput';
import { Form, withFormik, FormikProps, FormikErrors } from 'formik';
import { passwordValidator } from 'utils/validators';
import { sleep } from 'utils/sleep';
import { Navigate } from 'react-router-dom';

export interface PasswordFormValues {
    password: string;
    passwordValidate: string;
}

interface InnerPasswordFormProps {
    userId?: number;
}

const InnerPasswordForm: React.FC<InnerPasswordFormProps & FormikProps<PasswordFormValues>> = ({
    isSubmitting,
    touched,
    values,
    errors,
    isValid,
    handleChange,
    handleBlur,
    status,
}) => {
    const toast = useToast();
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (status == 'success') {
            toast({
                title: 'Passord registrert',
                duration: 10000,
                status: 'success',
                isClosable: true,
            });
            sleep(500).then(() => setRedirect(true));
        } else if (status == 'error') {
            toast({
                title: 'Feil oppstod',
                description: 'En feil oppstod ved registrering av passord. Ta kontakt med leder.',
                duration: 10000,
                status: 'error',
                isClosable: true,
            });
        }
    }, [status]);

    if (redirect) {
        return <Navigate to="/logg-inn" />;
    }

    return (
        <Flex flexDirection="column" maxW="40ch">
            <Form>
                <Stack spacing={4}>
                    <SBInput
                        value={values.password}
                        name="password"
                        type="password"
                        onChange={handleChange}
                        placeholder="Skriv inn passord"
                        label="Passord"
                        autoComplete="off"
                        onBlur={handleBlur}
                        isDisabled={isSubmitting}
                    />
                    <SBInput
                        value={values.passwordValidate}
                        formControl={{
                            isInvalid:
                                (touched.password && !!errors.password) ||
                                (touched.passwordValidate && !!errors.passwordValidate),
                        }}
                        name="passwordValidate"
                        type="password"
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder="Skriv inn passord på nytt"
                        error={errors.password || errors.passwordValidate}
                        onBlur={handleBlur}
                        isDisabled={isSubmitting}
                    />
                    <Button
                        disabled={!isValid || isSubmitting || status == 'success'}
                        isLoading={isSubmitting || status == 'success'}
                        colorScheme="blue"
                        type="submit"
                        loadingText={'Lagrer passord'}
                    >
                        Lagre passord
                    </Button>
                </Stack>
            </Form>
        </Flex>
    );
};

interface PasswordFormProps extends InnerPasswordFormProps {
    onSubmit: (data: PasswordFormValues) => Promise<void>;
}

const PasswordForm = withFormik<PasswordFormProps, PasswordFormValues>({
    isInitialValid: false,
    mapPropsToValues: () => ({
        password: '',
        passwordValidate: '',
    }),
    validate: (values, { userId }) => {
        const errors: FormikErrors<PasswordFormValues> = {};

        errors.password = userId && values.password == '' ? undefined : passwordValidator(values.password);
        errors.passwordValidate =
            userId && values.password == ''
                ? undefined
                : errors.password == undefined && values.password != values.passwordValidate
                ? 'Passordene er ikke like'
                : undefined;

        const hasErrors = Object.values(errors).some((x) => x !== undefined);
        return hasErrors ? errors : {};
    },
    handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
        setSubmitting(true);
        const { onSubmit } = props;

        try {
            await onSubmit(values);
            setStatus('success');
        } catch (error) {
            setStatus('error');
        }
        setSubmitting(false);
    },
})(InnerPasswordForm);

export default PasswordForm;
