import React from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';

interface ISBPageHeadingProps extends HeadingProps {}

const SBPageHeading: React.FC<ISBPageHeadingProps> = ({ children, ...rest }) => {
    return (
        <Heading color="blue.700" size="lg" {...rest}>
            {children}
        </Heading>
    );
};

export default SBPageHeading;
