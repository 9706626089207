import React, { useEffect } from 'react';
import { Button, Flex, Stack, useToast } from '@chakra-ui/react';
import SBInput from 'components/atoms/textfield/SBInput';
import { Form, withFormik, FormikProps, FormikErrors } from 'formik';
import { emailValidator, nameValidator } from 'utils/validators';
import { postUser, putUser } from 'utils/api';
import SBSwitch from 'components/atoms/SBSwitch';
import { mutate } from 'swr';

interface UserFormValues {
    name: string;
    email: string;
    is_active: boolean;
    is_superuser: boolean;
}

interface InnerUserFormProps {
    userId?: number;
}

const InnerUserForm: React.FC<InnerUserFormProps & FormikProps<UserFormValues>> = ({
    isSubmitting,
    touched,
    values,
    errors,
    isValid,
    handleChange,
    handleBlur,
    resetForm,
    status,
    setStatus,
    userId,
}) => {
    const toast = useToast();
    useEffect(() => {
        if (status == 'success') {
            toast({
                title: userId ? 'Bruker oppdatert' : 'Bruker laget',
                description: userId
                    ? 'Brukeren er oppdatert!'
                    : `Ny bruker er laget. Kan logge inn med ${values.email}. `,
                status: 'success',
                duration: 10000,
                isClosable: true,
            });
            setStatus(undefined);
            if (!userId) {
                resetForm();
            }
        }
    }, [status]);

    return (
        <Flex flexDirection="column" maxW="40ch">
            <Form>
                <Stack spacing={4}>
                    <SBInput
                        formControl={{ isInvalid: touched.name && !!errors.name }}
                        placeholder="Ola Nordmann"
                        label="Navn"
                        step="0.5"
                        name="name"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        error={errors.name}
                        isDisabled={isSubmitting}
                    />
                    <SBInput
                        formControl={{ isInvalid: touched.email && !!errors.email }}
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        placeholder="ola@sjohaugbygg.no"
                        label="Epostadresse"
                        autoComplete="off"
                        error={errors.email}
                        onBlur={handleBlur}
                        isDisabled={isSubmitting}
                    />
                    {userId && (
                        <SBSwitch
                            name="is_active"
                            isChecked={values.is_active}
                            onChange={handleChange}
                            label="Aktiv bruker"
                        />
                    )}
                    {userId && (
                        <SBSwitch
                            name="is_superuser"
                            isChecked={values.is_superuser}
                            onChange={handleChange}
                            label="Admin"
                        />
                    )}
                    <Button
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        colorScheme="blue"
                        type="submit"
                        loadingText={'Lagrer'}
                    >
                        {userId ? 'Oppdater bruker' : 'Opprett ny bruker'}
                    </Button>
                </Stack>
            </Form>
        </Flex>
    );
};

interface UserFormProps extends InnerUserFormProps {
    name?: string;
    email?: string;
    is_superuser?: boolean;
    is_active?: boolean;
}

const UserForm = withFormik<UserFormProps, UserFormValues>({
    mapPropsToValues: ({ userId, is_superuser, is_active, name, email }) => ({
        name: name ?? '',
        email: email ?? '',
        is_active: userId ? is_active! : true,
        is_superuser: userId ? is_superuser! : false,
    }),
    validate: (values) => {
        const errors: FormikErrors<UserFormValues> = {};

        errors.name = nameValidator(values.name);
        errors.email = emailValidator(values.email);

        const hasErrors = Object.values(errors).some((x) => x !== undefined);
        return hasErrors ? errors : {};
    },
    handleSubmit: async (values, { props, setSubmitting, setFieldError, setStatus }) => {
        setSubmitting(true);
        try {
            if (props.userId) {
                const updateData: Partial<UserFormValues> = { ...values };
                await putUser({ ...updateData, id: props.userId });
            } else {
                await postUser(values.name, values.email);
            }
            setStatus('success');
            mutate(['/api/users']);
        } catch (error) {
            setFieldError('email', 'En bruker er allerede registrert med denne eposten');
        }
        setSubmitting(false);
    },
})(InnerUserForm);

export default UserForm;
