import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, IconButton, useBreakpointValue } from '@chakra-ui/react';
import moment, { Moment } from 'moment';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const borderColor = 'blue.300';
const backgroundColor = 'blue.200';
const backgroundColorHover = 'blue.300';
const bakgroundColorDisabled = 'gray.300';
const dayColor = 'black';

interface ICalendar {
    renderCell: (day: Moment) => JSX.Element;
    renderFooter?: () => JSX.Element;
    onCellSelect?: (day: Moment) => void;
    onNext?: (day: Moment) => void;
    onPrev?: (day: Moment) => void;
    navDisabeled?: boolean;
    initialDate?: Moment;
}

const Calendar: React.FC<ICalendar> = ({
    renderCell,
    renderFooter,
    onCellSelect,
    onNext,
    onPrev,
    navDisabeled,
    initialDate,
}) => {
    const [currentMonth, setCurrentMonth] = useState(initialDate ?? moment());
    const [selectedDay, setSelectedDay] = useState(moment());

    useEffect(() => {
        if (!initialDate) {
            onCellSelect?.(selectedDay);
        }
    }, []);

    const onNextClick = () => {
        const newDate = currentMonth.add(1, 'months').clone();
        setCurrentMonth(newDate);
        onNext?.(newDate);
    };
    const onPrevClick = () => {
        const newDate = currentMonth.subtract(1, 'month').clone();
        setCurrentMonth(newDate.clone());
        onPrev?.(newDate);
    };

    const onCellClick = (day: Moment) => {
        setSelectedDay(day);
        onCellSelect?.(day);
    };

    return (
        <Flex width="full" flexGrow={0} flexDir="column">
            <CalendarHeader
                navDisabeled={navDisabeled}
                date={currentMonth.clone()}
                onNext={onNextClick}
                onPrev={onPrevClick}
            />
            <CalendarDayNames date={currentMonth.clone()} />
            <CalendarDays
                date={currentMonth.clone()}
                onSelect={onCellClick}
                selected={selectedDay}
                renderCell={renderCell}
            />
            {renderFooter?.()}
        </Flex>
    );
};

interface ICalendarHeader {
    date: Moment;
    onNext: () => void;
    onPrev: () => void;
    navDisabeled?: boolean;
}

const CalendarHeader: React.FC<ICalendarHeader> = ({ date, onNext, onPrev, navDisabeled }) => {
    const dateFormat = 'MMMM YYYY';

    return (
        <Flex
            borderBottomStyle="solid"
            borderBottomWidth="1px"
            borderBottomColor={borderColor}
            flexDir="row"
            align="center"
            py={2}
            width="full"
        >
            <IconButton
                hidden={navDisabeled}
                variant="link"
                aria-label="next-month"
                onClick={() => onPrev()}
                justifySelf="flex-end"
            >
                <ChevronLeftIcon />
            </IconButton>
            <Heading
                flex="1"
                fontWeight="500"
                color="blue.700"
                textTransform="uppercase"
                textAlign="center"
                fontSize="115%"
            >
                {date.format(dateFormat)}
            </Heading>
            <IconButton
                hidden={navDisabeled}
                variant="link"
                icon={<ChevronRightIcon />}
                aria-label="next-month"
                onClick={onNext}
                justifySelf="flex-end"
            />
        </Flex>
    );
};

interface ICalendarDayNames {
    date: Moment;
}

const CalendarDayNames: React.FC<ICalendarDayNames> = ({ date }) => {
    const startDate = date.startOf('week').clone();
    const fontSize = useBreakpointValue({ base: '40%', md: '75%' });
    const format = useBreakpointValue({ base: 'ddd', sm: 'dddd' });
    const days = Array<Moment>(7)
        .fill(startDate)
        .map((day, i) => day.clone().add(i, 'days'))
        .map((day) => day.format(format))
        .map((dayString) => (
            <Text
                flexGrow={1}
                cursor={undefined}
                flexBasis={0}
                maxW="100%"
                justifyContent="center"
                textAlign="center"
                key={dayString}
            >
                {dayString}
            </Text>
        ));

    return (
        <Flex
            textTransform="uppercase"
            fontWeight="500"
            color="blue.700"
            fontSize={fontSize}
            padding=".75em 0"
            borderBottomStyle="solid"
            borderBottomWidth="1px"
            borderBottomColor={borderColor}
            width="full"
        >
            {days}
        </Flex>
    );
};

interface ICalendarDays {
    date: Moment;
    selected: Moment;
    onSelect: (day: Moment) => void;
    renderCell: (day: Moment) => React.ReactNode;
}

const CalendarDays: React.FC<ICalendarDays> = ({ date, onSelect, selected, renderCell }) => {
    const startOfMonth = date.clone().startOf('month');
    const startDate = startOfMonth.clone().startOf('week');
    const endOfMonth = date.clone().endOf('month');
    const endDate = endOfMonth.clone().endOf('week');

    const numberOfWeeks = Math.ceil(endDate.diff(startDate, 'days') / 7);
    const h = useBreakpointValue({ base: '4em', md: '5em' });

    const days = Array<Moment>(numberOfWeeks)
        .fill(startDate)
        .map((day, i) => day.clone().add(i, 'week'))
        .map((startOfWeek) =>
            Array<Moment>(7)
                .fill(startOfWeek)
                .map((day, i) => day.clone().add(i, 'day')),
        )
        .map((days, i) =>
            days.map((day) => {
                const dayString = day.format('D');
                const disabled = startOfMonth.diff(day, 'month') == -1 || endOfMonth.diff(day, 'month') == 1;
                return (
                    <Box
                        flexGrow={1}
                        pos="relative"
                        h={h}
                        borderRightWidth="1px"
                        borderRightColor={borderColor}
                        borderRightStyle="solid"
                        flexBasis={0}
                        maxW="100%"
                        cursor={disabled ? undefined : 'pointer'}
                        bg={
                            disabled
                                ? bakgroundColorDisabled
                                : day.isSame(selected, 'd')
                                ? backgroundColorHover
                                : backgroundColor
                        }
                        opacity={disabled ? 0.5 : 1.0}
                        justifyContent="center"
                        onClick={disabled ? undefined : () => onSelect(day.clone())}
                        pointerEvents={disabled ? 'none' : 'auto'}
                        _hover={
                            disabled
                                ? {}
                                : {
                                      transition: '0.5s ease-out',
                                      bg: backgroundColorHover,
                                  }
                        }
                        key={`${dayString}-${i}`}
                    >
                        <Box
                            color={dayColor}
                            fontSize="82.5%"
                            lineHeight="1"
                            fontWeight="400"
                            position="absolute"
                            top=".75em"
                            right=".75em"
                        >
                            {dayString}
                        </Box>
                        {renderCell(day)}
                    </Box>
                );
            }),
        )
        .map((days, i) => (
            <Flex
                width="full"
                borderBottomStyle="solid"
                borderBottomColor={borderColor}
                borderBottomWidth="1px"
                flexWrap="wrap"
                flexDirection="row"
                key={`week-${i}`}
            >
                {days}
            </Flex>
        ));

    return (
        <Flex width="full" flexDirection="column">
            {days}
        </Flex>
    );
};

function calendarPropsAreEqual(prevCalendar: ICalendar, nextCalendar: ICalendar) {
    return (
        prevCalendar.initialDate === nextCalendar.initialDate &&
        prevCalendar.onCellSelect === nextCalendar.onCellSelect &&
        prevCalendar.onNext == nextCalendar.onNext &&
        prevCalendar.onPrev == nextCalendar.onPrev &&
        prevCalendar.renderCell == nextCalendar.renderCell &&
        prevCalendar.renderFooter == nextCalendar.renderFooter
    );
}

export default React.memo(Calendar, calendarPropsAreEqual);
