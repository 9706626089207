import { AccordionItem, Text, AccordionButton, Box, AccordionIcon, AccordionPanel, Flex } from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';
import LockIcon from 'components/atoms/lock/Lock';
import HourForm from 'components/molecules/HourForm';
import { useUser } from 'hooks/useUser';
import { Hour } from 'models/apiModels';
import { Moment } from 'moment';
import React from 'react';

interface IHourAccordionItem {
    date: Moment;
    entry?: Hour;
    showLock?: boolean;
}

const HourAccordionItem: React.FC<IHourAccordionItem> = ({ date, entry, showLock }) => {
    const { data } = useUser();

    return (
        <AccordionItem borderColor="blue.200">
            <AccordionButton
                color="blue.700"
                fontWeight="500"
                _expanded={{ bg: 'blue.300', color: 'white', fontWeight: 'bold' }}
            >
                <Box flex="1" textAlign="left">
                    {date.format('dddd, D. MMMM')}
                </Box>
                {showLock && entry && <LockIcon locked={entry.locked} scale={0.5} marginBottom={4} />}
                {entry != undefined && <WorkdHourIcon hours={entry.hours} />}
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg="blue.200">
                <HourForm
                    {...entry}
                    week={date.get('week')}
                    date={date}
                    user_id={data?.id}
                    month={date.month() + 1}
                    year={date.year()}
                />
            </AccordionPanel>
        </AccordionItem>
    );
};

const WorkdHourIcon = ({ hours }: { hours: number }) => {
    return (
        <Flex flexDir="row" align="center">
            <TimeIcon />
            <Text ml={2}>{hours}</Text>
        </Flex>
    );
};

export default HourAccordionItem;
