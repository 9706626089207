import { useLocalStorage } from 'hooks/useLocaleStorage';
import * as React from 'react';

type ToggableKeys = 'hide-employees-0-hours' | 'hide-inactive-projects';

type ToggableState = Record<ToggableKeys, boolean>;

const initialToggableState: ToggableState = {
    'hide-employees-0-hours': true,
    'hide-inactive-projects': false,
};

type State = {
    flags: ToggableState;
    toggleFlag: (key: ToggableKeys) => void;
};

const UserPreferencesContext = React.createContext<State | null>(null);

interface UserPreferencesProviderProps {
    children?: React.ReactNode;
}

const UserPreferencesProvider: React.FC<UserPreferencesProviderProps> = ({ children }) => {
    const [flags, setFlags] = useLocalStorage<ToggableState>('flags', initialToggableState);

    const toggleFlag = (key: ToggableKeys) => {
        const oldValue = flags[key];
        setFlags({ ...flags, [key]: !oldValue });
    };

    return <UserPreferencesContext.Provider value={{ flags, toggleFlag }}>{children}</UserPreferencesContext.Provider>;
};

const usePreferenceFlag = (key: ToggableKeys): [boolean, () => void] => {
    const context = React.useContext(UserPreferencesContext);

    if (context === null) {
        throw new Error('usePreferenceFlag must be used within a UserPreferencesProvider');
    }

    const toggle = () => context.toggleFlag(key);

    return [context.flags[key], toggle];
};

export { UserPreferencesProvider, usePreferenceFlag };
