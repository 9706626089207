import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { AuthProvider } from 'context/auth';
import moment from 'moment';
import theme from 'theme';
import 'moment/locale/nb';
import { UserPreferencesProvider } from 'context/userPreferences';
moment.locale('nb');

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <AuthProvider>
                <UserPreferencesProvider>
                    <CSSReset />
                    <App />
                </UserPreferencesProvider>
            </AuthProvider>
        </ChakraProvider>
    </React.StrictMode>,
);
