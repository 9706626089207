import { Tr, Td } from '@chakra-ui/react';
import { HourStatus } from 'models/apiModels';
import moment from 'moment';
import React from 'react';

interface IHourStatusRowProps extends HourStatus {}

const HourStatusRow: React.FC<IHourStatusRowProps> = ({ name, last_created }) => {
    const registerd = moment(last_created);

    return (
        <Tr>
            <Td px={0}>{name}</Td>
            <Td>{registerd.format('dddd, D. MMM YYYY')}</Td>
        </Tr>
    );
};

export default HourStatusRow;
