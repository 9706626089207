import React from 'react';
import {
    Button,
    ButtonProps,
    Flex,
    Spacer,
    Menu,
    MenuList,
    MenuDivider,
    MenuItem,
    MenuButton,
    Stack,
} from '@chakra-ui/react';
import LogoutButton from 'components/atoms/LogoutButton';
import { useNavigate } from 'react-router-dom';
import Greeting from 'components/atoms/Greeting';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useIsSuperUser } from 'hooks/useIsSuperUser';

const NavBarButtonStyle: ButtonProps = {
    variant: 'link',
    color: 'blue.700',
    _focus: { border: 'none' },
    _hover: { color: 'blue.500' },
    _active: { color: 'blue.500' },
};

const DropDownButtonStyle: ButtonProps = {
    _hover: { bg: 'blue.500' },
    _active: { bg: 'blue.500' },
    _focus: { bg: 'blue.500' },
    bg: 'blue.400',
};
const NavBar: React.FC = () => {
    const navigate = useNavigate();
    const superUser = useIsSuperUser();

    const onClick = (route: string) => {
        navigate(route);
    };

    return (
        <Flex
            borderBottom="1px"
            borderBottomStyle="solid"
            borderBottomColor="blue.300"
            flexDir={['column', 'row']}
            align={['center', 'space-between']}
            py="2"
        >
            <Greeting />
            <Spacer />
            <Stack
                direction="row"
                align="center"
                justify={['center', superUser ? 'space-between' : 'flex-end']}
                flex={2}
            >
                <Button fontSize={['xs', 'md']} onClick={() => onClick('/')} {...NavBarButtonStyle}>
                    Timeføring
                </Button>

                <Button fontSize={['xs', 'md']} onClick={() => onClick('/oversikt')} {...NavBarButtonStyle}>
                    Oversikt
                </Button>
                {superUser && (
                    <Menu>
                        <MenuButton
                            fontSize={['xs', 'md']}
                            as={Button}
                            {...NavBarButtonStyle}
                            rightIcon={<ChevronDownIcon />}
                        >
                            Administrere
                        </MenuButton>
                        <MenuList borderColor="blue.400" color="white" bg="blue.400">
                            <MenuItem
                                fontSize={['xs', 'md']}
                                onClick={() => onClick('/prosjekter')}
                                {...DropDownButtonStyle}
                            >
                                Prosjekter
                            </MenuItem>
                            <MenuDivider borderColor="white" my={0} />
                            <MenuItem
                                fontSize={['xs', 'md']}
                                onClick={() => onClick('/rapporter')}
                                {...DropDownButtonStyle}
                            >
                                Rapporter
                            </MenuItem>
                            <MenuDivider borderColor="white" my={0} />
                            <MenuItem
                                fontSize={['xs', 'md']}
                                onClick={() => onClick('/brukere')}
                                {...DropDownButtonStyle}
                            >
                                Brukere
                            </MenuItem>
                        </MenuList>
                    </Menu>
                )}
                <LogoutButton />
            </Stack>
        </Flex>
    );
};

export default NavBar;
