import React from 'react';
import FullFlex from 'components/atoms/FullFlex';
import { Box } from '@chakra-ui/react';
import NavBar from 'components/organism/NavBar';
import MonthlySummary from 'components/molecules/MonthlySummary';
import ProjectSummary from 'components/molecules/SummaryProject';
import HourStatusOverview from 'components/molecules/HourStatusOverview';
import SBPageHeading from 'components/atoms/SBPageHeading';

const ReportsPage: React.FC = () => {
    return (
        <FullFlex align={['flex-start', 'center']} flexDir="column">
            <NavBar />
            <Box>
                <Box py={4}>
                    <SBPageHeading>Timeføring status</SBPageHeading>
                    <HourStatusOverview />
                </Box>
                <Box py={4}>
                    <SBPageHeading>Månedlig timeliste ansatt</SBPageHeading>
                    <MonthlySummary />
                </Box>
                <Box py={4}>
                    <SBPageHeading>Rapport timer prosjekt</SBPageHeading>
                    <ProjectSummary />
                </Box>
            </Box>
        </FullFlex>
    );
};

export default ReportsPage;
