import React from 'react';
import FullFlex from 'components/atoms/FullFlex';
import { Box } from '@chakra-ui/react';
import NavBar from 'components/organism/NavBar';
import UserForm from 'components/molecules/UserForm';
import ManageUsers from 'components/molecules/ManageUsers';
import SBPageHeading from 'components/atoms/SBPageHeading';

const ManageUsersPage: React.FC = () => {
    return (
        <FullFlex align={['flex-start', 'center']} flexDir="column">
            <NavBar />
            <Box width="full" py={4} overflow="hidden">
                <SBPageHeading>Administrer brukere</SBPageHeading>
                <ManageUsers />
            </Box>
            <Box>
                <SBPageHeading pb={3}>Legg til bruker</SBPageHeading>
                <Box>
                    <UserForm />
                </Box>
            </Box>
        </FullFlex>
    );
};

export default ManageUsersPage;
