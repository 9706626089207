import { Hour } from 'models/apiModels';
import useSWR, { Fetcher } from 'swr';
import { getHoursMonth } from 'utils/api';
import { useUser } from './useUser';

const useHours = (month: number, year: number, userId?: number) => {
    const { data: user } = useUser();
    const fetcher: Fetcher<Hour[], [string, string, number, number]> = ([, user_id, month, year]: [
        string,
        string,
        number,
        number,
    ]) => getHoursMonth(user_id, month, year);
    const { data, error } = useSWR(user ? ['/api/user', `${userId ?? user?.id}`, month, year] : null, fetcher);

    return { data, error };
};

export { useHours };
