import { baseUrl } from 'constants/apiConstants';
import { authFetch } from './auth';
import { handleResponse } from './common';

export const del = async <T>(url: string, auth = false) => {
    const request = new Request(baseUrl + url, {
        method: 'DELETE',
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });

    if (auth) {
        return authFetch<T>(request);
    }

    return fetch(request).then((res) => handleResponse<T>(res));
};
