import React from 'react';
import classnames from 'classnames';
import './lock.scss';
import { Flex } from '@chakra-ui/react';

interface ILockProps extends React.CSSProperties {
    locked: boolean;
    scale?: number;
}

const LockIcon: React.FC<ILockProps> = ({ locked, scale = 0.75, ...rest }) => {
    const classes = classnames('lock', locked ? '' : 'unlocked');
    return (
        <Flex height="24px">
            <span className={classes} style={{ transform: `scale(${scale})`, marginTop: 4, ...rest }}></span>
        </Flex>
    );
};

export default LockIcon;
