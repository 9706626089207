import React from 'react';
import {
    InputGroup,
    InputRightElement,
    Input,
    Button,
    InputLeftElement,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { ISBInput } from './SBInput';
import { LockIcon } from '@chakra-ui/icons';

interface ISBPasswordInput extends Omit<ISBInput, 'type' | 'label'> {}

const SBPasswordInput: React.FC<ISBPasswordInput> = ({ ...rest }) => {
    const [show, setShow] = React.useState(false);
    const onShow = () => setShow(!show);

    return (
        <FormControl>
            <FormLabel>Passord</FormLabel>
            <InputGroup size="md">
                <InputLeftElement pointerEvents="none">
                    <LockIcon />
                </InputLeftElement>
                <Input pr="4.5rem" type={show ? 'text' : 'password'} colorScheme="teal" variant="solid" {...rest} />
                <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" variant="ghost" colorScheme="blue" onClick={onShow}>
                        {show ? 'Gjem' : 'Vis'}
                    </Button>
                </InputRightElement>
            </InputGroup>
        </FormControl>
    );
};

export default SBPasswordInput;
