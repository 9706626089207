import React from 'react';
import SBSelect from 'components/atoms/SBSelect';
import { FormControlProps, SelectProps } from '@chakra-ui/react';
import useSWR from 'swr';
import { getProjects } from 'utils/api';

interface ISBProjectSelectProps extends SelectProps {
    formControl?: FormControlProps;
    error?: string;
    showDisabeled?: boolean;
}

const SBProjectSelect: React.FC<ISBProjectSelectProps> = ({ showDisabeled, ...rest }) => {
    const { data } = useSWR(['/api/projects'], getProjects);

    if (!data) {
        return <SBSelect label="Prosjekt" values={[]} isDisabled={true} />;
    }

    return (
        <SBSelect
            label="Prosjekt"
            values={data
                .filter((item) => item.is_active || showDisabeled)
                .map((item) => ({ label: item.name, value: item.id }))}
            placeholder="Velg prosjekt"
            {...rest}
        />
    );
};

export default SBProjectSelect;
