import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';

const useWeek = (): { days: Moment[]; week: number; year: number; next: () => void; prev: () => void } => {
    const [startOfWeek, setStartOfWeek] = useState<Moment>(moment().startOf('week').utc(true));
    const [days, setDays] = useState<Moment[]>([moment()]);

    useEffect(() => {
        const days = getWeekDays(startOfWeek);
        setDays(days);
    }, [startOfWeek]);

    const next = () => setStartOfWeek(days[0].add(1, 'week'));
    const prev = () => setStartOfWeek(days[0].subtract(1, 'week'));

    const week = startOfWeek.week();
    const year = startOfWeek.year();

    return { days, week, next, year, prev };
};

const getWeekDays = (week: Moment) => {
    const days = [];
    for (let i = 0; i <= 6; i++) {
        days.push(moment(week).add(i, 'days'));
    }

    return days;
};

export { useWeek };
