import { FormControl, FormControlProps, FormErrorMessage, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import React from 'react';

export interface ISBSelectProps extends SelectProps {
    label: string;
    values: { label: string; value: string | number }[];
    formControl?: FormControlProps;
    error?: string;
}

const SBSelect: React.FC<ISBSelectProps> = ({ values, label, error, formControl, ...rest }) => {
    return (
        <FormControl {...formControl}>
            <FormLabel color="blue.700">{label}</FormLabel>
            <Select variant="solid" {...rest}>
                {values.map((item, i) => (
                    <option key={`select-item-${i}`} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </Select>
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    );
};

export default SBSelect;
