import { EditIcon } from '@chakra-ui/icons';
import {
    Tr,
    Td,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import ProjectForm from 'components/molecules/ProjectForm';
import { Project } from 'models/apiModels';
import React, { useState } from 'react';
import { mutate } from 'swr';
import { putProject } from 'utils/api';
import SBSwitch from './SBSwitch';

interface IManageProjectRowProps extends Project {}

const ManageProjectRow: React.FC<IManageProjectRowProps> = ({ is_active, name, id }) => {
    const [isChecked, setIsCheked] = useState(is_active);
    const { onOpen, onClose, isOpen } = useDisclosure();

    const onToggle = async () => {
        setIsCheked(!isChecked);
        await putProject({ is_active: !is_active, id });
        mutate(['/api/projects']);
    };
    return (
        <Tr>
            <Td px={0}>{name}</Td>
            <Td w={1}>
                <SBSwitch isChecked={isChecked} onChange={onToggle} />
            </Td>
            <Td w={1}>
                <IconButton
                    colorScheme="blue"
                    onClick={onOpen}
                    aria-label="edit-user"
                    size="md"
                    mx={0}
                    variant="link"
                    icon={<EditIcon />}
                    alignSelf="center"
                />
            </Td>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent color="blue.700" bg="blue.100">
                    <ModalHeader>Rediger prosjekt</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <ProjectForm name={name} id={id} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Tr>
    );
};

export default ManageProjectRow;
