import React from 'react';
import { InputGroup, InputLeftElement, Input, FormControl, FormLabel } from '@chakra-ui/react';
import { ISBInput } from './SBInput';

interface ISBInputLeftIcon extends ISBInput {
    children: JSX.Element;
}

const SBInputLeftIcon: React.FC<ISBInputLeftIcon> = ({ label, children, ...rest }) => {
    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <InputGroup>
                <InputLeftElement pointerEvents="none">{children}</InputLeftElement>
                <Input {...rest} variant="solid" />
            </InputGroup>
        </FormControl>
    );
};

export default SBInputLeftIcon;
