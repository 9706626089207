import React from 'react';
import LoginPage from 'components/pages/Login';
import HomePage from 'components/pages/Home';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { RequireAuth } from 'utils/ProtectedRoute';
import OverviewPage from 'components/pages/Overview';
import ProjectsPage from 'components/pages/admin/Projects';
import ManageUsersPage from 'components/pages/admin/Users';
import ReportsPage from 'components/pages/admin/Reports';
import RegisterPasswordPage from 'components/pages/RegisterPassword';
import ResetPasswordPage from 'components/pages/ResetPassword';

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <HomePage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/oversikt"
                    element={
                        <RequireAuth>
                            <OverviewPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/prosjekter"
                    element={
                        <RequireAuth>
                            <ProjectsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/brukere"
                    element={
                        <RequireAuth>
                            <ManageUsersPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/rapporter"
                    element={
                        <RequireAuth>
                            <ReportsPage />
                        </RequireAuth>
                    }
                />
                <Route path="/fullfør-registrering" element={<RegisterPasswordPage />} />
                <Route path="/nullstill-passord" element={<ResetPasswordPage />} />
                <Route path="/logg-inn" element={<LoginPage />} />
            </Routes>
        </Router>
    );
};

export default App;
