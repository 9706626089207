import React from 'react';
import { Button } from '@chakra-ui/react';
import { accessTokenKey } from 'constants/apiConstants';
import { useNavigate } from 'react-router-dom';
import { logout } from 'utils/api';
import { getTokens } from 'utils/auth';
import { useSWRConfig } from 'swr';

const LogoutButton: React.FC = () => {
    const navigate = useNavigate();
    const { mutate } = useSWRConfig();
    const onLogout = () => {
        const { refreshToken } = getTokens();
        localStorage.removeItem(accessTokenKey);
        localStorage.clear();
        logout(refreshToken);
        mutate(() => true, undefined, { revalidate: false });
        navigate('/logg-inn', { replace: true });
    };
    return (
        <Button fontSize={['xs', 'md']} onClick={onLogout} variant="link" color="red.300" _hover={{ color: 'red.500' }}>
            Logg ut
        </Button>
    );
};

export default LogoutButton;
