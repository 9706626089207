import React from 'react';
import FullFlex from 'components/atoms/FullFlex';
import { Box } from '@chakra-ui/react';
import NavBar from 'components/organism/NavBar';
import ProjectForm from 'components/molecules/ProjectForm';
import ManageProjects from 'components/molecules/ManageProjetcs';
import SBPageHeading from 'components/atoms/SBPageHeading';

const ProjectsPage: React.FC = () => {
    return (
        <FullFlex align={['flex-start', 'center']} flexDir="column">
            <NavBar />
            <Box width="full" py={4}>
                <Box>
                    <SBPageHeading>Administrer projsekter</SBPageHeading>
                    <ManageProjects />
                </Box>
                <Box>
                    <SBPageHeading pb={3}>Legg til prosjekt</SBPageHeading>
                    <Box>
                        <ProjectForm />
                    </Box>
                </Box>
            </Box>
        </FullFlex>
    );
};

export default ProjectsPage;
