import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import FullFlex from 'components/atoms/FullFlex';
import Logo from 'assets/logo.png';
import React from 'react';
import PasswordForm, { PasswordFormValues } from 'components/molecules/PasswordForm';
import { useQuery } from 'hooks/useQuery';
import { postActivateUser } from 'utils/api';

const RegisterPasswordPage: React.FC = () => {
    const query = useQuery();

    const onSubmit = async (values: PasswordFormValues) => {
        try {
            await postActivateUser(query.get('token') ?? '', values.password);
        } catch (error: unknown) {
            if (error instanceof Error && error.message.includes('line 1 column 1 of the JSON data')) {
                return;
            }
        }
    };

    return (
        <FullFlex outerContainerAlign="center" align="center" justifyContent="center" flex="1">
            <Box bg="grey.600" p="4" maxWidth="45ch" borderRadius={8}>
                <Flex justifyContent="center">
                    <Image src={Logo} />
                </Flex>
                <RegisterPasswordHeader />
                <PasswordForm onSubmit={onSubmit} />
            </Box>
        </FullFlex>
    );
};

const RegisterPasswordHeader = () => {
    return (
        <Box textAlign="center" my="4">
            <Heading fontWeight="400">Legg til passord </Heading>
            <Text>Du har fåttt en bruker i systemet, men for å fullføre aktiveringen må du gi den et passord.</Text>
        </Box>
    );
};

export default RegisterPasswordPage;
