import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Box, Button, Flex, Heading, ExpandedIndex, Text } from '@chakra-ui/react';
import { useUser } from 'hooks/useUser';
import FullFlex from 'components/atoms/FullFlex';
import HourAccordionItem from 'components/organism/HourAccordionItem';
import { useWeek } from 'hooks/useWeek';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { sleep } from 'utils/sleep';
import NavBar from 'components/organism/NavBar';
import SBLockHours from 'components/molecules/SBLockHours';
import { lockHours } from 'utils/api';
import { mutate } from 'swr';
import SBContentLoading from 'components/atoms/SBContentLoading';
import { useHoursWeek } from 'hooks/useHoursWeek';
import WeekHoursPrefetch from 'components/atoms/WeekHoursPrefetch';

const HomePage: React.FC = () => {
    const { data } = useUser();
    const [expandedIndex, setExpandedIndex] = useState<ExpandedIndex>(-1);
    const { days, week, year, next, prev } = useWeek();

    const date = days[0];
    const { data: hours } = useHoursWeek(week, year);

    useEffect(() => {
        setExpandedIndex(days.findIndex((item) => moment().isSame(item, 'day')));
    }, [days]);

    const allHoursLocked = useMemo(
        () =>
            hours
                ?.filter((item) => moment(item.date).utc(true).isSame(date, 'week'))
                .every((item) => item.locked == true),
        [days, hours],
    );

    const onChangeWeek = (toggle: () => void) => {
        if (expandedIndex) {
            sleep(200).then(toggle);
            setExpandedIndex(-1);
        } else {
            toggle();
        }
    };

    const onLockHours = async () => {
        if (data?.id == null)
            throw new Error('User id is null, this should not happen. Please contact an administrator');

        await lockHours(data.id, date.week(), date.year());
        mutate(['/api/hours/user/week/', week, year]);
    };

    const onNext = () => onChangeWeek(next);
    const onPrev = () => onChangeWeek(prev);

    return (
        <SBContentLoading isLoaded={data != undefined} label="Henter data">
            <FullFlex align={['flex-start', 'center']} flexDir="column">
                <WeekHoursPrefetch date={date.clone().add(1, 'week')} />
                <WeekHoursPrefetch date={date.clone().subtract(1, 'week')} />
                <NavBar />
                <Text align="center" fontSize={['sm', 'md']} pt={8} color="blue.700" fontWeight="500">
                    Her kan du se, registerere, endre eller låse dine timer. <br />
                    Timer som er låst kan ikke endres.
                </Text>
                <SBContentLoading isLoaded={!data || hours != undefined} label="Laster data">
                    <Heading width="100%" textAlign={'center'} color="blue.700" size=" lg" fontWeight="400" pt={8}>
                        Timer
                    </Heading>
                    <Flex width="full" alignSelf="normal" align="center">
                        <Button variant="link" onClick={onPrev}>
                            <ArrowLeftIcon color="blue.700" />
                        </Button>
                        <Box width="full">
                            <Heading
                                size="lg"
                                fontWeight="400"
                                color="blue.700"
                                textAlign="center"
                            >{`Uke ${week} `}</Heading>
                            <Text align="center" fontSize="lg" color="blue.700">
                                {year}
                            </Text>
                        </Box>
                        <Button variant="link" onClick={onNext}>
                            <ArrowRightIcon color="blue.700" />
                        </Button>
                    </Flex>
                    <Accordion onChange={setExpandedIndex} index={expandedIndex} pb={8} allowToggle>
                        {days.map((day) => (
                            <HourAccordionItem
                                showLock={true}
                                entry={hours?.find((item) => moment(item.date).utc(true).isSame(day, 'date'))}
                                key={`${day.dayOfYear()}-${data?.id}`}
                                date={day}
                            />
                        ))}
                    </Accordion>
                    <Flex width="full" justify="flex-end">
                        <SBLockHours
                            w={['100%', 'auto']}
                            mx={[2, 0]}
                            onClick={onLockHours}
                            state={allHoursLocked ?? true}
                        >
                            {allHoursLocked ? 'Timer låst' : 'Lås timer'}
                        </SBLockHours>
                    </Flex>
                </SBContentLoading>
            </FullFlex>
        </SBContentLoading>
    );
};

export default HomePage;
