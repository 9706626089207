import FullFlex from 'components/atoms/FullFlex';
import SBContentLoading from 'components/atoms/SBContentLoading';
import { accessTokenKey, refreshTokenKey } from 'constants/apiConstants';
import { useLocalStorage } from 'hooks/useLocaleStorage';
import * as React from 'react';
import useSWR from 'swr';
import { validateToken } from 'utils/api';

type State = {
    token: string;
    setToken: (token: string) => void;
    setRefreshToken: (token: string) => void;
};

const AuthContext = React.createContext<State | null>(null);

interface AuthProviderProps {
    children?: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken, refreshToken] = useLocalStorage(accessTokenKey, '');
    const [, setRefreshToken, refreshRefreshToken] = useLocalStorage(refreshTokenKey, '');

    React.useEffect(() => {
        refreshToken();
        refreshRefreshToken();
    }, []);

    return (
        <AuthContext.Provider value={{ token, setToken, setRefreshToken }}>
            <FullFlex outerContainerAlign="center">
                <SBContentLoading label="Laster data" isLoaded={true}>
                    {children}
                </SBContentLoading>
            </FullFlex>
        </AuthContext.Provider>
    );
};

const useToken = (): [string, (token: string) => void, (refreshToken: string) => void] => {
    const context = React.useContext(AuthContext);

    if (context === null) {
        throw new Error('useToken must be used within a AuthProvider');
    }

    return [context.token, context.setToken, context.setRefreshToken];
};

const useIsSignedIn = (): boolean => {
    const context = React.useContext(AuthContext);
    const { error } = useSWR(context?.token ?? 'validate-token', validateToken);

    if (context === null) {
        throw new Error('useIsSignedIn must be used within a AuthProvider');
    }

    if (context?.token == '') {
        return false;
    }

    if (error !== undefined) {
        return false;
    }

    return true;
};

export { AuthProvider, useToken, useIsSignedIn };
