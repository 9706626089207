import React from 'react';
import { Container, Flex, FlexProps } from '@chakra-ui/react';

interface IFullFlexProps extends FlexProps {
    outerContainerAlign?: string;
}

const FullFlex: React.FC<IFullFlexProps> = ({
    outerContainerAlign = 'flex-start',
    bg = 'blue.100',
    children,
    ...rest
}) => {
    return (
        <Flex py={[4, 8]} minH="100vh" width="full" bg={bg} justifyContent="center" align={outerContainerAlign}>
            <Container maxW="80ch" px="0.75rem" {...rest}>
                {children}
            </Container>
        </Flex>
    );
};

export default FullFlex;
