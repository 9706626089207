import { Box, Flex, Image } from '@chakra-ui/react';
import FullFlex from 'components/atoms/FullFlex';
import LoginForm from 'components/molecules/LoginForm';
import Logo from 'assets/logo.png';
import React from 'react';

const LoginPage: React.FC = () => {
    return (
        <FullFlex outerContainerAlign="center" align="center" justifyContent="center" flex="1">
            <Box bg="grey.600" p="4" maxWidth="45ch" borderRadius={8}>
                <Flex justifyContent="center">
                    <Image src={Logo} />
                </Flex>
                <LoginForm />
            </Box>
        </FullFlex>
    );
};

export default LoginPage;
