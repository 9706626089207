import React from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useDisclosure,
    ButtonProps,
} from '@chakra-ui/react';
import LockIcon from 'components/atoms/lock/Lock';

interface ISBLockHoursProps extends Omit<ButtonProps, 'onClick' | 'id'> {
    state: boolean;
    onClick?: () => void;
}

const SBLockHours: React.FC<ISBLockHoursProps> = ({ state, onClick, ...rest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onToggle = () => {
        onClose();
        onClick?.();
    };

    return (
        <>
            <Button
                onClick={onOpen}
                isDisabled={state}
                variant="solid"
                colorScheme="blue"
                leftIcon={<LockIcon locked={state} />}
                {...rest}
            />
            <ConfirmCloseDialog onClose={onClose} onOpen={onToggle} isOpen={isOpen} />
        </>
    );
};

const ConfirmCloseDialog = ({
    onClose,
    onOpen,
    isOpen,
}: {
    onClose: () => void;
    onOpen: () => void;
    isOpen: boolean;
}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cancelRef = React.useRef<any>();

    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent color="blue.700" bg="blue.100">
                    <AlertDialogHeader>Lås Timer</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Er du sikker på at du vil låse timene? Timer som er låst kan ikke endres.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Nei
                        </Button>
                        <Button onClick={onOpen} colorScheme="blue" ml={3}>
                            Ja, lås timene
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default SBLockHours;
