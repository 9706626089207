import { Table, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import ManageProjectRow from 'components/atoms/ManageProjectRow';
import SBContentLoading from 'components/atoms/SBContentLoading';
import SBSwitch from 'components/atoms/SBSwitch';
import { usePreferenceFlag } from 'context/userPreferences';
import React from 'react';
import useSWR from 'swr';
import { getProjects } from 'utils/api';

const ManageProjects: React.FC = () => {
    const { data } = useSWR(['/api/projects'], getProjects);
    const [hideInactive, toggleInactive] = usePreferenceFlag('hide-inactive-projects');

    return (
        <SBContentLoading label="Laster prosjekter" isLoaded={data != undefined}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th px={0} color="blue.700">
                            Navn
                        </Th>
                        <Th color="blue.700">Aktiv</Th>
                        <Th color="blue.700">Rediger</Th>
                    </Tr>
                </Thead>
                {data
                    ?.filter((item) => item.is_active || hideInactive)
                    .sort((a, b) => a.id - b.id)
                    .map((item) => <ManageProjectRow key={item.id} {...item} />)}
                <Tfoot>
                    <Tr>
                        <Th px={0} color="blue.700">
                            Vis inaktive prosjekter
                        </Th>
                        <Th color="blue.700">
                            <SBSwitch isChecked={hideInactive} onChange={toggleInactive} />
                        </Th>
                    </Tr>
                </Tfoot>
            </Table>
        </SBContentLoading>
    );
};

export default ManageProjects;
