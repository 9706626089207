import { Table, Th, Thead, Tr, VStack, useBoolean, useBreakpointValue } from '@chakra-ui/react';
import ManageUserRow from 'components/atoms/ManageUserRow';
import SBContentLoading from 'components/atoms/SBContentLoading';
import SBSwitch from 'components/atoms/SBSwitch';
import React from 'react';
import useSWR from 'swr';
import { getUsers } from 'utils/api';

const ManageUsers: React.FC = () => {
    const [showOnlyActiveUsers, { toggle }] = useBoolean();
    const { data } = useSWR(['/api/users'], getUsers);
    const size = useBreakpointValue({ base: 'sm', md: 'md' });
    const activeDisplay = useBreakpointValue({ base: 'none', sm: 'table-cell' });

    const filteredUsers = data
        ?.filter((item) => (!showOnlyActiveUsers || item.is_active) && !item.name.includes('Tidligere ansatt'))
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <SBContentLoading isLoaded={data != undefined} label="Laster brukere">
            <VStack py={3}>
                <SBSwitch
                    id="show-only-active-users-toggle"
                    isChecked={showOnlyActiveUsers}
                    onChange={toggle}
                    label="Vis kun aktive brukere?"
                />
                <Table size={size} variant="simple">
                    <Thead>
                        <Tr>
                            <Th px={0} color="blue.700">
                                Ansatt
                            </Th>
                            <Th color="blue.700">Epost</Th>
                            <Th display={activeDisplay} color="blue.700">
                                Aktiv
                            </Th>
                            <Th color="blue.700">Rediger</Th>
                        </Tr>
                    </Thead>
                    {filteredUsers?.map((item) => <ManageUserRow key={item.id} {...item} />)}
                </Table>
            </VStack>
        </SBContentLoading>
    );
};

export default ManageUsers;
