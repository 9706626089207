import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    HStack,
    Tag,
    TagLabel,
    TagLeftIcon,
    useBreakpointValue,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import SBSelect from 'components/atoms/SBSelect';
import React, { useMemo } from 'react';
import { useState } from 'react';
import useSWR from 'swr';
import { getProjectsSummary } from 'utils/api';
import moment from 'moment';
import ProjectSummaryDetail from 'components/atoms/SummaryProjectDetail';
import SBContentLoading from 'components/atoms/SBContentLoading';

interface IProjectSummaryFilter {
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
}

const ProjectSummary: React.FC = () => {
    const today = moment();
    const [filter, setFilter] = useState<IProjectSummaryFilter>({
        startMonth: today.get('month') + 1,
        startYear: today.get('year'),
        endMonth: today.get('month') + 1,
        endYear: today.get('year'),
    });
    const { startMonth, startYear, endMonth, endYear } = filter;
    const { data } = useSWR(['/api/projects/summary', startMonth, startYear, endMonth, endYear], getProjectsSummary);
    const _months = useBreakpointValue({ base: moment.monthsShort(), sm: moment.months() });

    const months = useMemo(
        () =>
            _months?.map((month, i) => ({
                label: month,
                value: i + 1,
            })) ?? [],
        [_months],
    );

    const years = useMemo(
        () =>
            Array(20)
                .fill(2020)
                .map((item, i) => ({ label: `${item + i}`, value: item + i })),
        [],
    );

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newState: IProjectSummaryFilter = { ...filter, [`${e.target.name}`]: parseInt(e.target.value) };

        setFilter(newState);
    };

    return (
        <>
            <HStack py={3}>
                <SBSelect value={startYear} values={years} label="Fra" name="startYear" onChange={handleChange} />
                <SBSelect
                    value={startMonth}
                    formControl={{ alignSelf: 'flex-end' }}
                    values={months}
                    label=""
                    name="startMonth"
                    onChange={handleChange}
                />
                <SBSelect value={endYear} values={years} label="Til" name="endYear" onChange={handleChange} />
                <SBSelect
                    value={endMonth}
                    values={months}
                    formControl={{ alignSelf: 'flex-end' }}
                    label=""
                    name="endMonth"
                    onChange={handleChange}
                />
            </HStack>
            <SBContentLoading label="Laster prosjekt rapporter" isLoaded={data != undefined}>
                <Accordion allowToggle allowMultiple>
                    {data
                        ?.sort((a, b) => b.hours - a.hours)
                        .map((item) => (
                            <AccordionItem
                                borderTopWidth={0}
                                key={`${item.name}-${startMonth}-${startYear}-${endMonth}-${endYear}`}
                            >
                                <AccordionButton
                                    bg="blue.400"
                                    color="white"
                                    my={1}
                                    _expanded={{ bg: 'blue.500', fontWeight: 'bold' }}
                                    _hover={{ bg: 'blue.500' }}
                                    _focus={undefined}
                                >
                                    <Box flex="1" textAlign="left">
                                        {item.name} - {item.hours}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={0} px={0}>
                                    <ProjectSummaryDetail
                                        id={item.project_id}
                                        startMonth={startMonth}
                                        startYear={startYear}
                                        endMonth={endMonth}
                                        endYear={endYear}
                                    />
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                </Accordion>
            </SBContentLoading>
            <Tag size="md" pl={0} mr={1} variant="subtle" colorScheme="blue">
                <TagLeftIcon boxSize="12px" as={InfoIcon} />
                <TagLabel>Trykk på et prosjekt for å se detaljert informasjon</TagLabel>
            </Tag>
        </>
    );
};

export default ProjectSummary;
