import { Box } from '@chakra-ui/react';
import { useHoursWeek } from 'hooks/useHoursWeek';
import { Moment } from 'moment';
import React from 'react';

const WeekHoursPrefetch: React.FC<{ date: Moment }> = ({ date }) => {
    const week = date.get('week');
    const year = date.get('year');

    const {} = useHoursWeek(week, year);

    return <Box hidden={true}></Box>;
};

export default WeekHoursPrefetch;
