import React from 'react';
import { useIsSignedIn } from 'context/auth';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
    children?: React.ReactNode;
}

export const RequireAuth: React.FC<PrivateRouteProps> = ({ children }): JSX.Element => {
    const isSignedIn = useIsSignedIn();

    return isSignedIn ? (children as JSX.Element) : <Navigate to="/logg-inn" />;
};
