import { baseUrl } from 'constants/apiConstants';
import { authFetch } from './auth';
import { handleResponse } from './common';

const createPostRequest = <T>(
    url: string,
    method: string,
    body: string | URLSearchParams | undefined,
    auth: boolean,
    headers: Record<string, string> = {},
) => {
    const request = new Request(baseUrl + url, {
        method,
        body,
        headers: {
            ...headers,
            'Access-Control-Allow-Origin': '*',
        },
    });

    return auth ? authFetch<T>(request) : fetch(request).then((res) => handleResponse<T>(res));
};

export const post = async <T>(url: string, auth = false) => {
    return createPostRequest<T>(url, 'POST', undefined, auth);
};

export const postJSON = async <T, D>(url: string, data: D, auth = false) => {
    const body = JSON.stringify(data);
    return createPostRequest<T>(url, 'POST', body, auth, { 'Content-Type': 'application/json' });
};

export const postForm = async <T>(url: string, data: Record<string, string>, auth = false) => {
    const formData = new URLSearchParams();
    for (const name in data) {
        if (Object.prototype.hasOwnProperty.call(data, name)) {
            formData.append(name, data[name]);
        }
    }
    return createPostRequest<T>(url, 'POST', formData, auth);
};
