import { useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T): [T, (val: T) => void, () => void] => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            return initialValue;
        }
    });

    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
        }
    };

    const refresh = () => {
        const item = window.localStorage.getItem(key);
        setValue(item ? JSON.parse(item) : initialValue);
    };

    return [storedValue, setValue, refresh];
};
