import React from 'react';
import { Flex, Text, Spinner } from '@chakra-ui/react';

interface ISBContentLoadingProps {
    label: string;
    isLoaded: boolean;
    children: React.ReactNode;
}

const SBContentLoading: React.FC<ISBContentLoadingProps> = ({ label, isLoaded, children }) => {
    if (isLoaded) {
        return children as JSX.Element;
    }

    return (
        <Flex minH={200} width="full" align="center" flexDir="column" justify="center">
            <Spinner size="lg" label={label} my={2} />
            <Text>{label}</Text>
        </Flex>
    );
};

export default SBContentLoading;
