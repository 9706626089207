type FormValidationValue<T> = T | undefined;

const numberValidator = (value: FormValidationValue<number>, min = 0, max = 100) => {
    if (value == undefined) {
        return 'Må fylles ut';
    }

    if (value < min) {
        return 'Kan ikke være mindre enn ' + min;
    }

    if (value > max) {
        return 'Kan ikke være større enn ' + max;
    }

    return undefined;
};

const hourValidator = (value: FormValidationValue<number>) => numberValidator(value, 1, 20);
const lunchValidator = (value: FormValidationValue<number>) => numberValidator(value, 0, 2);

const emailValidator = (email: string): string | undefined => {
    const emailReg = /.+@.+\.[A-Za-z]+$/;

    if (email == undefined) {
        return 'Må fylles ut';
    }

    if (email.length < 4) {
        return 'Eposten er ikke gyldig';
    }

    if (!new RegExp(emailReg).test(email)) {
        return 'Eposten er ikke gyldig';
    }

    return undefined;
};

const passwordValidator = (password: string): string | undefined => {
    const validation = [
        { key: /\d+/g, msg: 'Passordet må inneholde minst et tall' },
        { key: /[a-zæøå]/g, msg: 'Passordet må inneholde små bokstaver' },
        { key: /[A-ZÆØÅ]/g, msg: 'Passordet må inneholde store bokstaver' },
        { key: /^.{8,}$/g, msg: 'Passordet må inneholde minst 8 bokstaver' },
    ];

    for (const item in validation) {
        if (!new RegExp(validation[item].key).test(password)) {
            return validation[item].msg;
        }
    }

    return undefined;
};

const nameValidator = (name: string): string | undefined => {
    const nameReg = /^[a-zæøå]/gi;

    if (name == undefined || name.length == 0) {
        return 'Må fylles ut';
    }

    if (!new RegExp(nameReg).test(name)) {
        return 'Kun bokstavene A-Å';
    }

    return undefined;
};

const projectNameValidator = (name: string): string | undefined => {
    const nameReg = /[^A-Za-z0-9\s]+/gi;

    if (name == undefined || name.length === 0) {
        return 'Må fylles ut';
    }

    if (new RegExp(nameReg).test(name)) {
        return 'Kun bokstavene A-Å eller tall 0-9';
    }

    return undefined;
};

export { hourValidator, lunchValidator, emailValidator, projectNameValidator, nameValidator, passwordValidator };
