import React, { useCallback, useState } from 'react';
import { Box, Flex, Badge, Text, useMediaQuery } from '@chakra-ui/react';
import Calendar from 'components/atoms/Calendar';
import { Moment } from 'moment';
import LockIcon from 'components/atoms/lock/Lock';
import { useHours } from 'hooks/useHours';
import moment from 'moment';
import { Hour } from 'models/apiModels';
import CalendarPreFetch from 'components/atoms/CalendarPrefetch';

interface IHourCalendar {
    onCellSelect?: (day: Moment) => void;
    onHourSelect?: (hour: Hour | undefined) => void;
    month?: number;
    year?: number;
    userId?: number;
    navDisabeled?: boolean;
}

const HourCalendar: React.FC<IHourCalendar> = ({ onCellSelect, onHourSelect, month, year, userId, navDisabeled }) => {
    const [date, setDate] = useState<Moment>(month && year ? moment().set('year', year).set('month', month) : moment());

    // backend is not zero-indexed
    const _month = date.get('month') + 1;
    const _year = date.get('year');

    const { data } = useHours(_month, _year, userId);

    const onRenderCell = useCallback(
        (day: Moment) => {
            const hour = data?.find((item) => moment(item.date).isSame(day, 'd'));

            return <HourCalendarCell hour={hour} />;
        },
        [data],
    );

    const renderFooter = useCallback(
        () => <HourCalendarFooter hours={data?.reduce((curr, item) => item.hours + curr, 0) ?? 0} />,
        [data],
    );

    const onDateChange = useCallback((date: Moment) => setDate(date), []);

    const _onCellSelect = (date: Moment) => {
        const hour = data?.find((item) => moment(item.date, 'YYYY-MM-DD').isSame(date, 'day'));

        onHourSelect?.(hour);
        onCellSelect?.(date);
    };

    return (
        <>
            <CalendarPreFetch date={date.clone().add(1, 'months')} />
            <CalendarPreFetch date={date.clone().subtract(1, 'months')} />
            <Calendar
                onCellSelect={_onCellSelect}
                onNext={onDateChange}
                onPrev={onDateChange}
                renderCell={onRenderCell}
                navDisabeled={navDisabeled}
                renderFooter={renderFooter}
                initialDate={date.clone()}
            />
        </>
    );
};

interface IHourCalendarCell {
    hour?: Hour;
}

const HourCalendarCell: React.FC<IHourCalendarCell> = ({ hour }) => {
    const [isMobile] = useMediaQuery('(max-width: 500px)');

    if (!hour) {
        return null;
    }

    const hours = hour.hours;
    const projects = hour.hour_registration_projects.length;

    return (
        <>
            <Box color="black" fontSize="82.5%" lineHeight="1" fontWeight="400" position="absolute">
                <LockIcon locked={hour.locked} scale={0.4} />
            </Box>
            <Flex flexDir="column" width="full" height="full" pr="0.75em" pb="0.25em" justify="flex-end">
                {hours && (
                    <Badge fontWeight={400} fontSize="65%" variant="outline" colorScheme="black">
                        {hours} {isMobile ? 'T' : 'timer'}
                    </Badge>
                )}
                {projects && (
                    <Badge fontWeight={400} fontSize="65%" isTruncated variant="outline" colorScheme="black">
                        {`${projects} ${isMobile ? 'P' : projects > 1 ? 'prosjekter' : 'prosjekt'}`}
                    </Badge>
                )}
            </Flex>
        </>
    );
};

interface IHourCalendarFooter {
    hours: number;
}

const HourCalendarFooter: React.FC<IHourCalendarFooter> = ({ hours }) => {
    return (
        <Flex
            textTransform="uppercase"
            fontSize="70%"
            justify="flex-end"
            padding=".75em 0"
            align="center"
            borderBottomStyle="solid"
            borderBottomWidth="1px"
            borderBottomColor="blue.300"
            width="full"
        >
            <Text flexGrow={1} flexBasis={0} maxW="100%" pr="0.5em" justifyContent="center" textAlign="right">
                TOTALT ANTALL TIMER:
            </Text>
            <Text
                fontWeight="600"
                fontSize="120%"
                flexBasis={0}
                maxW="100%"
                pr="0.5em"
                justifyContent="center"
                textAlign="center"
            >
                {hours}
            </Text>
        </Flex>
    );
};

export default HourCalendar;
