import { FormControl, FormControlProps, FormErrorMessage, FormLabel, Switch, SwitchProps } from '@chakra-ui/react';
import React from 'react';

export interface ISBSwitchProps extends SwitchProps {
    placeholder?: string;
    formControl?: FormControlProps;
    label?: string;
    error?: string;
}

const SBSwitch: React.FC<ISBSwitchProps> = ({ label, error, formControl, ...rest }) => {
    return (
        <FormControl {...formControl} display="flex" flex={1} alignItems="center">
            <FormLabel color="blue.700" flex="1" hidden={!label}>
                {label}
            </FormLabel>
            <Switch {...rest} />
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    );
};

export default SBSwitch;
