import React from 'react';
import { Skeleton, Table, Tbody, Td, Th, Thead, Tr, useBreakpointValue } from '@chakra-ui/react';
import useSWR from 'swr';
import { getProjectSummaryDetail } from 'utils/api';

interface IProjectSummaryFilter {
    id: number;
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
}

const ProjectSummaryDetail: React.FC<IProjectSummaryFilter> = ({ id, startMonth, startYear, endMonth, endYear }) => {
    const { data } = useSWR(
        ['/api/projects/summary', id, startMonth, startYear, endMonth, endYear],
        getProjectSummaryDetail,
    );
    const size = useBreakpointValue({ base: 'sm', md: 'md' });

    return (
        <>
            <Skeleton isLoaded={data != undefined}>
                <Table variant="simple" size={size}>
                    <Thead>
                        <Tr>
                            <Th px={0} color="blue.700">
                                Ansatt
                            </Th>
                            <Th color="blue.700">Antall timer</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.map((item) => (
                            <Tr key={`${item.name}-${startMonth}-${startYear}-${endMonth}-${endYear}`}>
                                <Td px={0}>{item.name}</Td>
                                <Td>{item.hours}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Skeleton>
        </>
    );
};

export default ProjectSummaryDetail;
