import { Table, Tbody, Th, Thead, Tr, useBreakpointValue } from '@chakra-ui/react';
import HourStatusRow from 'components/atoms/HourStatusRow';
import SBContentLoading from 'components/atoms/SBContentLoading';
import React from 'react';
import useSWR from 'swr';
import { getHoursStatus } from 'utils/api';

const HourStatusOverview: React.FC = () => {
    const { data } = useSWR(['/api/hours/status'], getHoursStatus);
    const size = useBreakpointValue({ base: 'sm', md: 'md' });

    const filteredUsers = data
        ?.filter((item) => !item.name.includes('Tidligere ansatt'))
        .sort((a, b) => b.last_created.localeCompare(a.last_created));

    return (
        <SBContentLoading label="Laster timeførings data" isLoaded={data != undefined}>
            <Table variant="simple" size={size}>
                <Thead>
                    <Tr>
                        <Th px={0} color="blue.700">
                            Navn
                        </Th>
                        <Th color="blue.700">Timer sist registrert</Th>
                    </Tr>
                </Thead>
                <Tbody>{filteredUsers?.map((item, i) => <HourStatusRow key={`${item.name}-${i}`} {...item} />)}</Tbody>
            </Table>
        </SBContentLoading>
    );
};

export default HourStatusOverview;
