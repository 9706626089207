import React, { useEffect } from 'react';
import { Button, Flex, Stack, useToast } from '@chakra-ui/react';
import SBInput from 'components/atoms/textfield/SBInput';
import { Form, withFormik, FormikProps, FormikErrors } from 'formik';
import { postProject, putProject } from 'utils/api';
import { projectNameValidator } from 'utils/validators';
import { mutate } from 'swr';

interface ProjectFormValues {
    name: string;
    id?: number;
}

const InnerProjectForm: React.FC<FormikProps<ProjectFormValues>> = ({
    isSubmitting,
    touched,
    values,
    errors,
    isValid,
    status,
    handleChange,
    handleBlur,
    setStatus,
    resetForm,
}) => {
    const toast = useToast();

    useEffect(() => {
        if (status == 'success' || status == 'updated') {
            const updated = status == 'updated';
            toast({
                title: updated ? 'Prosjekt oppdatert' : 'Prosjekt laget',
                description: updated ? undefined : `Nytt prosjekt ${values.name} er nå laget og klar for bruk.`,
                status: 'success',
                duration: 10000,
                isClosable: true,
            });
            setStatus(undefined);

            if (!updated) {
                resetForm();
            }
        }
    }, [status]);

    return (
        <Flex maxW="40ch" flexDirection="column">
            <Form autoComplete="off">
                <Stack spacing={4}>
                    <SBInput
                        formControl={{ isInvalid: touched.name && !!errors.name }}
                        type="string"
                        placeholder="f.eks Tak Gravdalsveien 2"
                        label="Navn"
                        step="0.5"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        error={errors.name}
                        isDisabled={isSubmitting}
                    />
                    <Button
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        colorScheme="blue"
                        type="submit"
                        loadingText={'Lagrer'}
                    >
                        {values.id ? 'Oppdatert prosjekt' : 'Legg til prosjekt'}
                    </Button>
                </Stack>
            </Form>
        </Flex>
    );
};

interface ProjectFormProps {
    id?: number;
    name?: string;
}

const ProjectForm = withFormik<ProjectFormProps, ProjectFormValues>({
    mapPropsToValues: ({ name, id }) => ({
        name: name ?? '',
        id: id,
    }),
    validate: (values) => {
        const errors: FormikErrors<ProjectFormValues> = {};

        errors.name = projectNameValidator(values.name);

        const hasErrors = Object.values(errors).some((x) => x !== undefined);

        return hasErrors ? errors : {};
    },
    handleSubmit: async (values, { setSubmitting, props, setStatus, setFieldError }) => {
        setSubmitting(true);
        try {
            if (props.id) {
                await putProject({ id: props.id, name: values.name });
                setStatus('updated');
            } else {
                await postProject(values.name);
                setStatus('success');
            }
            mutate(['/api/projects']);
        } catch (error) {
            setFieldError('name', 'Et prosjekt med dette navnet eksisterer allerede');
        }

        setSubmitting(false);
    },
})(InnerProjectForm);

export default ProjectForm;
