import React from 'react';
import { FormControl, FormControlProps, FormErrorMessage, FormLabel, Input, InputProps } from '@chakra-ui/react';

export interface ISBInput extends InputProps {
    placeholder?: string;
    formControl?: FormControlProps;
    label?: string;
    error?: string;
}

const SBInput: React.FC<ISBInput> = ({ label, error, formControl, ...rest }) => {
    return (
        <FormControl {...formControl}>
            <FormLabel color="blue.700" hidden={!label}>
                {label}
            </FormLabel>
            <Input variant="solid" {...rest} />
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    );
};

export default SBInput;
