import {
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    PopoverFooter,
    ButtonGroup,
    IconButton,
    IconButtonProps,
} from '@chakra-ui/react';
import React from 'react';

interface ISBConfirmActionButton extends IconButtonProps {
    heading: string;
    body: string;
    onConfirm: () => void;
}

const SBConfirmActionButton: React.FC<ISBConfirmActionButton> = ({ heading, body, onConfirm, ...rest }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const open = () => setIsOpen(!isOpen);
    const _onConfirm = () => {
        setIsOpen(false);
        onConfirm();
    };
    const close = () => setIsOpen(false);
    return (
        <>
            <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={close} placement="top" closeOnBlur={false}>
                <PopoverTrigger>
                    <IconButton onClick={open} colorScheme="red" {...rest} />
                </PopoverTrigger>
                <PopoverContent borderColor="blue.400">
                    <PopoverHeader fontWeight="semibold">{heading}</PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>{body}</PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                        <ButtonGroup size="sm">
                            <Button variant="solid" onClick={close}>
                                Avbryt
                            </Button>
                            <Button colorScheme="red" onClick={_onConfirm}>
                                Ja
                            </Button>
                        </ButtonGroup>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default SBConfirmActionButton;
