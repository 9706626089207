/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getTokens, setTokens } from 'utils/auth';
import { HttpError } from './common';
import { renewToken } from 'utils/api';

type UnauthorizedRequestHandler = {
    resolve: (token: string) => void;
    reject: (error: unknown) => void;
};

let isRefreshingAccessToken = false;
let unauthorizedRequestsQueue: Array<UnauthorizedRequestHandler> = [];

const handleUnauthorizedRequests = (error: unknown, token: string | null = null) => {
    unauthorizedRequestsQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        }
        if (token) {
            prom.resolve(token);
        }
    });

    unauthorizedRequestsQueue = [];
};

export const authFetch = async <T>(request: Request) => {
    const { accessToken } = getTokens();
    request.headers.set('Authorization', `Bearer ${accessToken}`);
    const requestCopy = request.clone();

    try {
        const res = await fetch(request);
        return handleResponse<T>(res);
    } catch (error) {
        //@ts-ignore
        if (error.statusCode === 401) {
            return renewAndRetry<T>(requestCopy);
        } else {
            throw error;
        }
    }
};

const renewAndRetry = async <T>(request: Request): Promise<T> => {
    try {
        if (!isRefreshingAccessToken) {
            const { refreshToken } = getTokens();
            isRefreshingAccessToken = true;

            const tokens = await renewToken(refreshToken);
            setTokens(tokens.access_token, tokens.refresh_token);
            request.headers.set('Authorization', `Bearer ${tokens.access_token}`);
            handleUnauthorizedRequests(null, tokens.access_token);
            isRefreshingAccessToken = false;
        } else {
            return new Promise((resolve, reject) => {
                unauthorizedRequestsQueue.push({ resolve, reject });
            }).then((token) => {
                request.headers.set('Authorization', `Bearer ${token}`);
                return fetch(request).then((res) => handleResponse<T>(res));
            });
        }

        return fetch(request).then((res) => handleResponse<T>(res));
    } catch (error) {
        handleUnauthorizedRequests(error, null);
        throw error;
    }
};

function handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        throw new HttpError(response.statusText, response.status);
    }

    if (response.status === 204) {
        return Promise.resolve({} as T);
    }

    return response.json() as Promise<T>;
}
