import { accessTokenKey, refreshTokenKey } from 'constants/apiConstants';

const getTokens = (): { accessToken: string; refreshToken: string } => {
    const accessToken = JSON.parse(localStorage.getItem(accessTokenKey) || '');
    const refreshToken = JSON.parse(localStorage.getItem(refreshTokenKey) || '');
    return { accessToken, refreshToken };
};

const setTokens = (accessToken: string, refreshToken: string): void => {
    localStorage.setItem(refreshTokenKey, JSON.stringify(refreshToken));
    localStorage.setItem(accessTokenKey, JSON.stringify(accessToken));
};

export { getTokens, setTokens };
