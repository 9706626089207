import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useUser } from 'hooks/useUser';

const Greeting: React.FC = () => {
    const { data } = useUser();

    return (
        <Heading pb={[2, 0]} fontWeight="600" color="blue.700" size="md">
            {data ? `Hei, ${data.name} 👋🏻` : 'Hei'}
        </Heading>
    );
};

export default Greeting;
