import { useUser } from './useUser';

export const useIsSuperUser = (): boolean => {
    const { data: user, isLoading } = useUser();

    if (isLoading || !user) {
        return false;
    }

    return user.is_superuser;
};
