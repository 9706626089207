import React, { FormEvent, useState } from 'react';
import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import { AtSignIcon } from '@chakra-ui/icons';
import SBInputLeftIcon from 'components/atoms/textfield/SBInputLeftIcon';
import SBPasswordInput from 'components/atoms/textfield/SBPasswordInput';
import { authenticate } from 'utils/api';
import { useToken } from 'context/auth';
import { useNavigate } from 'react-router-dom';

const LoginForm: React.FC = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [, setAuthToken, setRefreshToken] = useToken();

    const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);
    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();
        await onLogin();
    };

    const onLogin = async () => {
        try {
            setError(undefined);
            setIsLoading(true);
            const data = await authenticate(username, password);
            setAuthToken(data.access_token);
            setRefreshToken(data.refresh_token);
            setIsLoading(false);
            navigate('/');
        } catch (error) {
            setError('Feil brukernavn eller passord!');
            setIsLoading(false);
        }
    };

    const loginEnabeled = username.length > 0 && password.length > 0;

    return (
        <Box>
            <LoginHeader />
            <form onSubmit={onSubmit}>
                <Stack spacing={4}>
                    <SBInputLeftIcon
                        value={username}
                        onChange={onUsernameChange}
                        label="Brukernavn"
                        placeholder="Skriv brukernavn her"
                        isDisabled={isLoading}
                    >
                        <AtSignIcon />
                    </SBInputLeftIcon>
                    <SBPasswordInput
                        isDisabled={isLoading}
                        value={password}
                        onChange={onPasswordChange}
                        placeholder="Skriv passord her"
                    />
                    <Text color="red.500" size="md" fontWeight="500" hidden={!error}>
                        {error}
                    </Text>
                    <Button
                        disabled={isLoading || !loginEnabeled}
                        isLoading={isLoading}
                        loadingText="Logger inn"
                        onClick={onLogin}
                        type="submit"
                        colorScheme="blue"
                    >
                        Logg inn
                    </Button>
                </Stack>
            </form>
        </Box>
    );
};

const LoginHeader = () => {
    return (
        <Box textAlign="center" my="4">
            <Heading fontWeight="400">Logg inn med din bruker</Heading>
            <Text>Om du ikke har en bruker ta kontakt med leder</Text>
        </Box>
    );
};

export default LoginForm;
