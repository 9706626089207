import {
    useDisclosure,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Td,
    Tr,
    Button,
    Stack,
    useToast,
    useBreakpointValue,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import UserForm from 'components/molecules/UserForm';
import { User } from 'models/apiModels';
import React, { useState } from 'react';
import SBPageHeading from './SBPageHeading';
import { postRecoverPassword, postResendActivationEmail, putUser } from 'utils/api';
import { mutate } from 'swr';

interface IManageUserRowProps extends User {}

type SendButtonState = 'idle' | 'loading' | 'error';

const ManageUserRow: React.FC<IManageUserRowProps> = ({ id, is_superuser, is_active, email, name }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const activeDisplay = useBreakpointValue({ base: 'none', sm: 'table-cell' });

    return (
        <Tr>
            <Td px={0}>{name}</Td>
            <Td>{email}</Td>
            <Td display={activeDisplay} width={1}>
                {is_active ? 'JA' : 'NEI'}
            </Td>
            <Td width={1}>
                <IconButton
                    colorScheme="blue"
                    onClick={onOpen}
                    aria-label="edit-user"
                    size="md"
                    mx={0}
                    variant="link"
                    icon={<EditIcon />}
                    alignSelf="center"
                />
            </Td>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent color="blue.700" bg="blue.100">
                    <ModalHeader>{name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <SBPageHeading fontSize="lg">Rediger konto</SBPageHeading>
                        <UserForm
                            is_active={is_active}
                            is_superuser={is_superuser}
                            userId={id}
                            email={email}
                            name={name}
                        />
                        <Stack my="1em">
                            <SBPageHeading fontSize="lg">Handlinger</SBPageHeading>
                            <SendActivationEmailButton userId={id} isActive={is_active} email={email} />
                            <SendResetPasswordEmailButton email={email} isActive={is_active} />
                            <SetUserToEarlierEmployee userId={id} />
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Tr>
    );
};

const SendResetPasswordEmailButton: React.FC<{ isActive: boolean; email: string }> = ({ isActive, email }) => {
    const [buttonState, setButtonState] = useState<SendButtonState>('idle');
    const toast = useToast();
    const onResendPasswordEmail = async () => {
        if (!isActive || buttonState == 'loading') {
            return;
        }

        try {
            setButtonState('loading');
            await postRecoverPassword(email);
            setButtonState('idle');
            toast({
                title: 'Epost sendt',
                description: `En epost for å nullstille passordet er sent til ${email}. Denne er gyldig i 24 timer.`,
                status: 'success',
                duration: 10000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Kunne ikke nullstille passord',
                description: `En feil oppstod ved nullstilling av passord. Prøv igjen senere.`,
                status: 'error',
                duration: 10000,
                isClosable: true,
            });
            setButtonState('error');
        }
    };

    return (
        <Button
            colorScheme="blue"
            onClick={onResendPasswordEmail}
            loadingText="Sender epost"
            isLoading={buttonState == 'loading'}
        >
            Nullstill passord
        </Button>
    );
};

const SendActivationEmailButton: React.FC<{ userId: number; isActive: boolean; email: string }> = ({
    userId,
    isActive,
    email,
}) => {
    const [buttonState, setButtonState] = useState<SendButtonState>('idle');
    const toast = useToast();
    const onResendActivationEmail = async () => {
        try {
            if (isActive || buttonState == 'loading') {
                return;
            }
            setButtonState('loading');
            await postResendActivationEmail(userId);
            setButtonState('idle');
            toast({
                title: 'Epost sendt',
                description: `En ny aktiveringsepost er sendt til ${email}. Denne er gyldig i 24 timer.`,
                status: 'success',
                duration: 10000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Kunne ikke sende aktiveringsepost',
                description: `En feil oppstod ved sending av aktiveringsepost. Prøv igjen senere.`,
                status: 'error',
                duration: 10000,
                isClosable: true,
            });
            setButtonState('error');
        }
    };

    return (
        <Button
            colorScheme="blue"
            onClick={onResendActivationEmail}
            loadingText="Sender epost"
            isLoading={buttonState == 'loading'}
        >
            Send ny aktiveringsepost
        </Button>
    );
};
const SetUserToEarlierEmployee: React.FC<{ userId: number }> = ({ userId }) => {
    const [buttonState, setButtonState] = useState<SendButtonState | 'confirm'>('idle');
    const toast = useToast();
    const onResendActivationEmail = async () => {
        try {
            if (buttonState == 'loading') {
                return;
            }

            if (buttonState == 'idle') {
                setButtonState('confirm');
                return;
            }

            setButtonState('loading');
            await putUser({
                id: userId,
                is_active: false,
                is_superuser: false,
                name: 'Tidligere ansatt',
                email: `tidligere-ansatt-${userId}@example.com`,
            });
            setButtonState('idle');
            toast({
                title: 'Ansatt markert som tidligere ansatt',
                status: 'success',
                duration: 10000,
                isClosable: true,
            });
            mutate('/api/users');
        } catch (error) {
            toast({
                title: 'Handlinge feilet',
                status: 'error',
                duration: 10000,
                isClosable: true,
            });
            setButtonState('error');
        }
    };

    return (
        <Button
            colorScheme="blue"
            onClick={onResendActivationEmail}
            loadingText="Oppdaterer"
            isLoading={buttonState == 'loading'}
        >
            {buttonState == 'confirm' ? 'Er du sikker?' : 'Sett til tidligere ansatt'}
        </Button>
    );
};

export default ManageUserRow;
