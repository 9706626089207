import useSWR from 'swr';
import { getHoursWeek } from 'utils/api';
import { useUser } from './useUser';

const useHoursWeek = (week: number, year: number) => {
    const { data: user } = useUser();
    const { data, error } = useSWR(user ? ['/api/hours/user/week/', week, year] : null, getHoursWeek);

    return { data, error };
};

export { useHoursWeek };
