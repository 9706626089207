import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    HStack,
    ModalContent,
    ModalOverlay,
    Modal,
    ModalBody,
    ModalHeader,
    ModalCloseButton,
    Tag,
    TagLabel,
    TagLeftIcon,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import { InfoIcon, WarningIcon } from '@chakra-ui/icons';
import SBSelect from 'components/atoms/SBSelect';
import React, { useMemo } from 'react';
import { useState } from 'react';
import useSWR from 'swr';
import { getHoursMonthSummary } from 'utils/api';
import moment from 'moment';
import HourCalendar from './HourCalendar';
import HourForm from './HourForm';
import { Hour } from 'models/apiModels';
import SBContentLoading from 'components/atoms/SBContentLoading';
import SBSwitch from 'components/atoms/SBSwitch';
import { usePreferenceFlag } from 'context/userPreferences';

const MonthlySummary: React.FC = () => {
    const today = moment();
    const [month, setMonth] = useState(today.get('month') + 1);
    const [year, setYear] = useState(today.get('year'));
    const [hideEmployeesZeroHours, toggleHideEmployeesZeroHours] = usePreferenceFlag('hide-employees-0-hours');
    const { data } = useSWR(['/api/hours/summary', month, year], getHoursMonthSummary);
    const _months = useBreakpointValue({ base: moment.monthsShort(), sm: moment.months() });
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [selectedHour, setSelectedhour] = useState<Hour | undefined>(undefined);

    const months = useMemo(
        () =>
            _months?.map((month, i) => ({
                label: month,
                value: i + 1,
            })) ?? [],
        [_months],
    );

    const years = useMemo(
        () =>
            Array(20)
                .fill(2020)
                .map((item, i) => ({ label: `${item + i}`, value: item + i })),
        [],
    );

    const onHourSelect = (hour: Hour | undefined) => {
        setSelectedhour(hour);
        if (hour) {
            onOpen();
        }
    };

    return (
        <>
            <HStack py={3}>
                <SBSelect value={year} values={years} label="År" onChange={(e) => setYear(parseInt(e.target.value))} />
                <SBSelect
                    value={month}
                    values={months}
                    label="Måned"
                    onChange={(e) => setMonth(parseInt(e.target.value))}
                />
            </HStack>
            <SBSwitch
                label="Skjul ansatte med ingen timer"
                isChecked={hideEmployeesZeroHours}
                onChange={toggleHideEmployeesZeroHours}
                id="hide-employees-0"
            />
            <SBContentLoading label="Laster time rapporter" isLoaded={data != undefined}>
                <Accordion allowToggle>
                    {data
                        ?.sort((a, b) => b.hours - a.hours)
                        .filter((item) => (hideEmployeesZeroHours ? item.hours > 0 : true))
                        .map((item) => (
                            <AccordionItem borderTopWidth={0} key={`${item.email}-${month}-${year}`}>
                                <AccordionButton
                                    bg="blue.400"
                                    color="white"
                                    my={1}
                                    _expanded={{ bg: 'blue.500', fontWeight: 'bold' }}
                                    _hover={{ bg: 'blue.500' }}
                                    _focus={undefined}
                                >
                                    <Box flex="1" textAlign="left">
                                        {item.name} - {item.hours}
                                    </Box>
                                    {!item.all_locked && item.hours > 0 && (
                                        <Tag size="sm" mr={1} variant="subtle" colorScheme="orange">
                                            <TagLeftIcon boxSize="12px" as={WarningIcon} />
                                            <TagLabel>Ikke alle timer er låst!</TagLabel>
                                        </Tag>
                                    )}
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={8} px={0}>
                                    <HourCalendar
                                        onHourSelect={onHourSelect}
                                        year={year}
                                        month={month - 1}
                                        userId={item.user_id}
                                        navDisabeled={true}
                                    />
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                </Accordion>
            </SBContentLoading>
            <Tag size="md" pl={0} mr={1} variant="subtle" colorScheme="blue">
                <TagLeftIcon boxSize="12px" as={InfoIcon} />
                <TagLabel>Trykk på et navn for å se kalender med alle timeføringer</TagLabel>
            </Tag>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg="blue.100">
                    <ModalHeader color="blue.700">Timeføring Detaljer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <HourForm
                            {...selectedHour}
                            date={selectedHour ? moment(selectedHour.date) : moment()}
                            year={year}
                            month={month}
                            isAdmin={true}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MonthlySummary;
